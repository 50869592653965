.coal-index-css table {
    border-collapse: collapse;
  }
  .coal-index-css th, .coal-index-css td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  .coal-index-css tr:nth-child(even) {
    background-color: #eee;
  }
  .coal-index-css tr:nth-child(odd) {
    background-color: #fff;
  }