#loading-spinner {
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/opacity/see-through */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
  width: 100%;
  height: 100%;
  text-align: center;
}

#loader {
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/opacity/see-through */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
  width: 100%;
  height: 100%;
  text-align: center;
}

.floating {
  /* position: absolute;  */
  /* make the element position absolute to the container */
  /* bottom: -50px; */
   /* position the element just below the water */
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  50% {
    transform: translateX(20px) translateY(-10px);
  }
  100% {
    transform: translateX(0px) translateY(0px);
  }
}

