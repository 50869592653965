.bg-img {
    /* background-image:
        url('../../../images/img3.jpg'); */
    background-size: cover;
    /* opacity: 0.8; */
    height: 100vh;
    background-size: cover;
    background-position: center;
}
.imgcontainer h2{
    text-align: center;
    cursor: pointer;
    position: relative;
    /* background: none;   */
    background: linear-gradient(to right, #1cc91c,rgb(84, 128, 241),rgb(217, 48, 191)); 
    /* background-size: 200% auto; */
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    overflow: visible;
    animation: shine 1s linear infinite;
}

.bg-img:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
}

.login {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999;
    text-align: center;
    padding: 60px 32px;
    width: 370px;
    transform: translate(-50%, -50%);
    background: #e0deee;
    /* rgba(255, 255, 255, 0.04); */
    box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75);
    border-radius: 25px;
}

.login header {
    color: white;
    font-size: 33px;
    font-weight: 600;
    margin: 0 0 35px 0;
    font-family: 'Montserrat', sans-serif;
}

.login {
    color: white;
    margin: 20px 0;
    font-family: 'Poppins', sans-serif;
}